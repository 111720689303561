<template>
  <div class="font-weight-bold text-h5 text-sm-h4 px-0 pt-10 pt-sm-16">{{ title }}</div>
</template>
<script>
export default {
  name: 'TitleBlock',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
