<template>
    <div v-if="item && item.avatar" class="d-flex flex-grow-0 align-center grey--text text--darken-3">
      <div class="py-1 pr-2">
        <v-btn
          dense
          depressed
          small
          class="cs-alter-bg white--text"
        >
          出品人
        </v-btn>
      </div>
      <div v-if="item && item.avatar" class="flex-grow-0 pr-2">
        <v-avatar size="30">
          <img :src="item.avatar" :alt="item.name">
        </v-avatar>
      </div>
      <div v-if="item && item.name" class="flex-grow-0 pr-2 text-no-wrap">{{ item.name }}</div>
      <div v-if="item && item.career" class="flex-grow-1 text-truncate">{{ item.career }}</div>
    </div>
</template>
<script>
export default {
  name: 'AgendaLeader',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-alter-bg
  background-image: linear-gradient(to right, #016ACB 0%, #01BDCB 100%)
  box-shadow: inset 0 -2px 3px 0 rgba(255,255,255,0.50)
</style>