<template>
  <v-tooltip
    :top="!is_mobile"
    :bottom="is_mobile"
    content-class="cs-hover-bg"
    open-on-click
    :open-on-focus="false"
    :open-on-hover="!is_mobile"
    :open-delay="300"
    :close-delay="150"
    :nudge-top="is_mobile ? 20 : -10"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-card
          v-bind="attrs"
          v-on="on"
          :elevation="hover ? 5 : 2"
          outlined
          :class="['fill-height', 'pa-1', 'cs-border-left', 'card-base', {'card-hover': hover}]"
        >
          <v-card-text class="d-flex fill-height flex-column">
            <div class="flex-grow-0 pb-3 grey--text text--darken-3 d-flex align-center">
              <v-img
                  width="12"
                  height="12"
                  :src="require('@/assets/time.svg')"
                  class="flex-grow-0"
                />
                <div class="pl-1">{{ item.time }}</div>
            </div>
            <div class="flex-grow-1 text-h6 grey--text text--darken-4 font-weight-medium pb-3">{{ item.title }}</div>
            <div class="d-flex flex-grow-0 align-center grey--text text--darken-3">
              <div v-if="item.avatar" class="flex-grow-0 pr-2">
                <v-avatar
                  size="22"
                >
                  <img
                    :src="item.avatar"
                    :alt="item.name"
                  >
                </v-avatar>
              </div>
              <div v-if="item.name" class="flex-grow-0 pr-2 text-no-wrap">{{ item.name }}</div>
              <div v-if="item.career" class="flex-grow-1 text-truncate">{{ item.career }}</div>
            </div>
          </v-card-text>
          <div
            v-if="item.is_tea"
            class="d-inline-block cs-plus-card"
          >
            <v-img
              max-width="75"
              :src="require('@/assets/tea.svg')"
            />
          </div>
          <div
            v-if="item.is_lunch"
            class="d-inline-block cs-plus-card"
          >
            <v-img
              max-width="75"
              :src="require('@/assets/lunch.svg')"
            />
          </div>
        </v-card>
      </v-hover>
    </template>
    <div class="grey--text text--darken-2 pa-5 ma-0" :style="detailWidth">
      <div class="pb-1 grey--text text--darken-3 d-flex align-center">
        <v-img
          width="12"
          height="12"
          :src="require('@/assets/time.svg')"
          class="flex-grow-0"
        />
        <div class="pl-1">{{ item.time }}</div>
      </div>
      <div class="text-subtitle-1 font-weight-bold pb-2 grey--text text--darken-4">{{ item.title }}</div>
      <div v-if="!item.is_tea && !item.is_lunch && !item.is_ended && !item.is_discuz" class="d-flex align-center grey--text text--darken-3">
        <div>主讲嘉宾：</div>
        <div v-if="item.avatar" class="flex-grow-0 pr-2">
          <v-avatar size="22">
            <img :src="item.avatar" :alt="item.name">
          </v-avatar>
        </div>
        <div v-if="item.name" class="flex-grow-0 pr-2 text-no-wrap">{{ item.name }}</div>
      </div>
      <div v-if="!item.is_tea && !item.is_lunch && !item.is_ended && !item.is_discuz" class="text-caption pb-1">{{ item.career }}</div>
      <div v-if="item.content">{{ item.content }}</div>
      <div v-if="item.is_lunch || item.is_ended">{{ item.name }}</div>
    </div>
  </v-tooltip>
</template>
<script>
import Tools from '@/mixins/Tools.js'
export default {
  name: 'AgendaCard',
  mixins: [Tools],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-border-left
  border-left: solid 4px #16C8D9
.cs-plus-card
  position: absolute
  right: 10px
  bottom: 10px
.card-base
  transition: all .2s
.card-hover
  transform: translateY(-7px)
  box-shadow: 0 26px 40px -24px rgba(147, 157, 178, 0.3)
.cs-hover-bg
  background: #FFFFFF
  border: 1px solid #ECECEC
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.15)
  padding: 0
  margin: 0
  opacity: 0.99
  point-events: painted
</style>
