<template>
  <v-container
    fluid
    class="pa-0 ma-0"
  >
    <title-block :title="main_title" />
    <agenda-leader class="mt-2 mt-sm-3" :item="main_leader" />
    <agenda-block :items="mains" />
    <title-block :title="lunch_title.title" />
    <agenda-block :items="lunchs" />
    <title-block title="分论坛" />
    <v-tabs
      v-model="venue_tab"
      class="grey--text text--darken-4 cs-tabs-border"
      :show-arrows="is_mobile"
    >
      <v-tabs-slider color="#1B1327"></v-tabs-slider>
      <v-tab class="grey--text text--darken-4 font-weight-medium px-6 px-sm-6 cs-tab-width">{{ first_title }}</v-tab>
      <v-tab class="grey--text text--darken-4 font-weight-medium px-6 px-sm-6 cs-tab-width">{{ second_title }}</v-tab>
      <v-tab class="grey--text text--darken-4 font-weight-medium px-6 px-sm-6 cs-tab-width">{{ third_title }}</v-tab>
      <v-tab class="grey--text text--darken-4 font-weight-medium px-6 px-sm-6 cs-tab-width">{{ fourth_title }}</v-tab>
      <v-tab class="grey--text text--darken-4 font-weight-medium px-6 px-sm-6 cs-tab-width">{{ fifth_title }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="venue_tab">
      <v-tab-item>
        <agenda-leader class="mt-2 mt-sm-3" :item="first_leader" />
        <agenda-block :items="firsts" />
      </v-tab-item>
      <v-tab-item>
        <agenda-leader class="mt-2 mt-sm-3" :item="second_leader" />
        <agenda-block :items="seconds" />
      </v-tab-item>
      <v-tab-item>
        <agenda-leader class="mt-2 mt-sm-3" :item="third_leader" />
        <agenda-block :items="thirds" />
      </v-tab-item>
      <v-tab-item>
        <agenda-leader class="mt-2 mt-sm-3" :item="fourth_leader" />
        <agenda-block :items="fourth" />
      </v-tab-item>
      <v-tab-item>
        <agenda-leader class="mt-2 mt-sm-3" :item="fifth_leader" />
        <agenda-block :items="fifth" />
      </v-tab-item>
    </v-tabs-items>
    <title-block :title="ended_title.title" />
    <agenda-block :items="endeds" />
  </v-container>
</template>
<script>
import AgendaBlock from '@/views/components/AgendaBlock.vue'
import TitleBlock from '@/views/components/TitleBlock.vue'
import AgendaLeader from '@/views/components/AgendaLeader.vue'
import Datas from '@/mixins/Datas.js'
import Tools from '@/mixins/Tools.js'
export default {
  name: 'MainVenue',
  mixins: [Tools, Datas],
  components: {
    TitleBlock,
    AgendaLeader,
    AgendaBlock
  },
  data() {
    return {
      venue_tab: 0
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-tabs-border
  border-bottom: solid 1px #D8D8D8
.cs-tab-width
  min-width: 70px
</style>
<style lang="sass">
.v-slide-group__prev--disabled
  display: none !important
.v-slide-group__next--disabled
  display: none !important
</style>
