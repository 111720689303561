<template>
  <v-row class="py-2 py-sm-6">
    <v-col
      v-for="(item, index) of items"
      :key="`agenda-${item.branch}-${index}`"
      class="col-12 col-sm-3 align-self-stretch"
    >
      <agenda-card :item="item" />
    </v-col>
  </v-row>
</template>
<script>
import AgendaCard from '@/views/components/AgendaCard.vue'
export default {
  name: 'AgendaBlock',
  components: {
    AgendaCard
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
