import { mapGetters } from 'vuex'
import {
  DATACENTER,
  EVENTCENTER
} from '@/datacenter.js'

export default {
  computed: {
    ...mapGetters(['event_id']),
    // 主席团
    chairmans() {
      return this.get_lecturer('chairman')
    },
    // 委员会
    committees() {
      return this.get_lecturer('committee')
    },
    // 首页提炼的会议嘉宾
    lecturers() {
      const tmp_main = this.get_lecturer('main')
      const tmp_first = this.get_lecturer('1st')
      // 清理重复的嘉宾
      const tmp_end = []
      let tmp_exist = true
      for(let j=0;j < tmp_first.length; j++) {
        for (let i=0; i < tmp_main.length; i++) {
          if (tmp_main[i]['avatar'] === tmp_first[j]['avatar']) {
            tmp_exist = true
            break;
          } else {
            tmp_exist = false
          }
        }
        if (!tmp_exist) {
          tmp_end.push(tmp_first[j])
        }
      }
      return [ ...tmp_main, ...tmp_end ]
    },
    // 主论坛嘉宾
    mainLecturer() {
      return this.get_lecturer('main')
    },
    // 分论坛一嘉宾
    firstLecturer() {
      return this.get_lecturer('1st')
    },
    // 分论坛二嘉宾
    secondLecturer() {
      return this.get_lecturer('2nd')
    },
    // 分论坛三嘉宾
    thirdLecturer() {
      return this.get_lecturer('3rd')
    },
    // 分论坛四嘉宾
    fourthLecturer() {
      return this.get_lecturer('4th')
    },
    // 分论坛五嘉宾
    fifthLecturer() {
      return this.get_lecturer('5th')
    },
    // 主论坛议程
    mains() {
      return this.get_agenda('main')
    },
    // 午餐时间
    lunchs() {
      return this.get_agenda('lunch')
    },
    // 结束时间
    endeds() {
      return this.get_agenda('ended')
    },
    // 分论坛一议程
    firsts() {
      return this.get_agenda('1st')
    },
    // 分论坛二议程
    seconds() {
      return this.get_agenda('2nd')
    },
    // 分论坛三议程
    thirds() {
      return this.get_agenda('3rd')
    },
    // 分论坛四议程
    fourth() {
      return this.get_agenda('4th')
    },
    // 分论坛四议程
    fifth() {
      return this.get_agenda('5th')
    },
    // 主论坛标题
    main_title() {
      return this.get_title('main')
    },
    // 午餐时间
    lunch_title() {
      return { title: '午餐交流' }
    },
    // 午餐时间
    ended_title() {
      return { title: '全员合影及抽奖' }
    },
    // 分论坛一标题
    first_title() {
      return this.get_title('1st')
    },
    // 分论坛二标题
    second_title() {
      return this.get_title('2nd')
    },
    // 分论坛三标题
    third_title() {
      return this.get_title('3rd')
    },
    // 分论坛四标题
    fourth_title() {
      return this.get_title('4th')
    },
    // 分论坛四标题
    fifth_title() {
      return this.get_title('5th')
    },
    // 主论坛出品人
    main_leader() {
      return this.get_leader('main')
    },
    // 分论坛一出品人
    first_leader() {
      return this.get_leader('1st')
    },
    // 分论坛二出品人
    second_leader() {
      return this.get_leader('2nd')
    },
    // 分论坛三出品人
    third_leader() {
      return this.get_leader('3rd')
    },
    // 分论坛四出品人
    fourth_leader() {
      return this.get_leader('4th')
    },
    // 分论坛四出品人
    fifth_leader() {
      return this.get_leader('5th')
    }
  },
  methods: {
    get_title(branch) {
      const tmp = EVENTCENTER.find((item)=> {
        return item.branch === branch
      })
      return tmp.title
    },
    get_agenda(branch) {
      const tmp_datetime = DATACENTER.filter((item) => {
        return item.event_id === this.event_id && item.branch === branch && item.time
      })
      return tmp_datetime.sort((a, b) => a.title_order - b.title_order)
    },
    get_lecturer(branch) {
      const tmp_lecturers = DATACENTER.filter((item) => {
        if (branch === 'chairman' || branch === 'committee') {
          return item.event_id === this.event_id && item.branch === branch && item.avatar
        }
        return item.event_id === this.event_id && item.branch === branch && item.avatar && item.time
      })
      return tmp_lecturers.sort((a, b) => a.avatar_order - b.avatar_order)
    },
    get_leader(branch) {
      const tmp = DATACENTER.find((item)=> {
        return item.branch === branch && item.leader === true
      })
      return tmp ? tmp : {}
    }
  }
}